import { state } from 'cerebral'
import { callSequence, getState, setStoreWrapper } from '../../../utils/StoreManager'
import {
  TYPE_CHAT,
  TYPE_DATING,
  TYPE_FANS, TYPE_I_LIKE, TYPE_LIVE_ONLY,
  TYPE_MATCH,
  TYPE_ONLINE, TYPE_REAL_DATING, TYPE_SPONSORS,
  TYPE_SUBS_POSTS, TYPE_TOP_M, TYPE_TOP_W, TYPE_VIDEO_ONLY, TYPE_VIP
} from '../../posts/signals/getPosts'
import { imVIP, isMyProfileSet, myProfileId } from '../../../utils/my'
import { showErrorSnackbar } from '../../../utils/snackbarUtil'
import { setNewFansViewed, setNewMatchViewed } from '../../fans/fansUtil'
import { markAllPostsViewed } from '../../../utils/newPostsCountUtli'
import { checkGEONotification } from '../../auth/actions/notificationUtil'
import { isDatingApp } from '../../../utils/appConfigUtil'
import { datingNeedProfile } from '../../posts/signals/postLike'
// import { deactivateSponsors } from '../../../components/Screens/Posters/Filters'

export function selectFeed(id) {
  callSequence('sponsors.deactivateSponsors')
  const filters = getState('posts.filters')
  filters.map(item => {
    const groupId = Object.keys(item)[0]
    item[groupId].map(i => {
      if (typeof i !== 'number') {
        if (i.id === id) {
          i.selected = true
        } else if (i.id !== id && i.selected) {
          i.selected = false
        }
      }
    })
  })
  if (id === TYPE_FANS){
    setNewFansViewed()
  } else if (id === TYPE_MATCH){
    setNewMatchViewed()
  } else if (id === TYPE_SUBS_POSTS){
    markAllPostsViewed()
  }

  // else if (id === TYPE_DATING){
  //   checkGEONotification()
  // }
  setStoreWrapper('posts.selectedFeed', id)
}

export function disableFeed(id) {
  const filters = getState('posts.filters')
  filters.map(item => {
    const groupId = Object.keys(item)[0]
    item[groupId].map(i => {
      if (typeof i !== 'number') {
        if (i.id === id && i.selected) {
          i.selected = false
        }
      }
    })
  })

  setStoreWrapper('posts.selectedFeed', null)
}

export function isOnlySubs() {
  return getState('posts.selectedFeed') === TYPE_SUBS_POSTS
}

export function isOnlyVIP() {
  return getState('posts.selectedFeed') === TYPE_VIP
}

export function checkSelectedFeed(value) {
  return getState('posts.selectedFeed') === value
}

export function getSelectedTags() {
  const tags = []
  const filters = getState('posts.filters')
  filters.map(item => {
    const groupId = Object.keys(item)[0]
    item[groupId].map(i => {
      if (i.selected && !isMainFeedType(i.id) && i.tagsId && i.tagsId.length) {
        tags.push(i.tagsId[0])
      }
    })
  })
  return tags
}

export function isMainFeedType(id) {
  return (id === TYPE_DATING ||
    id === TYPE_REAL_DATING ||
    id === TYPE_I_LIKE ||
  id === TYPE_ONLINE ||
  id === TYPE_FANS ||
  id === TYPE_CHAT ||
  id === TYPE_LIVE_ONLY ||
  id === TYPE_MATCH ||
  id === TYPE_SPONSORS ||
  id === TYPE_VIP ||
    id === TYPE_VIDEO_ONLY ||
    id === TYPE_TOP_M ||
    id === TYPE_TOP_W ||
  id === TYPE_SUBS_POSTS)
}
