import { loadUser } from '@/utils/userLoader'
import config from '@/config'
const { vbApiUrl, ftCoreUrl } = config
import { getSessionId } from '@/utils'

let errorTime

export default [
  ({ store, props }) => {
    if (!props.uid || !getSessionId()) return

    loadUser(props.uid, async users => {
      const user = users[props.uid]
      if (!user || user.followersCount >= 0 || !getSessionId() || (errorTime && errorTime - Date.now() < 5000)) { return }

      try {
        fetch(`${ftCoreUrl}/api/v1/subscriptions/followers/count?uid=${props.uid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
          },
        })
        .then(async fResponse => {
          const fans = await fResponse.json()
          try {
            store.set(`app.users.${props.uid}.followersCount`, fans.count || 0)
          } catch (e) { console.log('@@@ load counts error', e) }
        }).catch(e => console.warn('catch error', e))
        fetch(`${ftCoreUrl}/api/v1/subscriptions/authors/count?uid=${props.uid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
          },
        })
        .then(async aResponse => {
          const subs = await aResponse.json()
          try {
            store.set(`app.users.${props.uid}.authorsCount`, subs.count || 0)
          } catch (e) {
            errorTime = Date.now()
            console.log('@@@ load counts error', e)
          }
        }).catch(e => {
          errorTime = Date.now()
          console.warn('catch error', e) })
      } catch (e) {  errorTime = Date.now()
        console.log('@@@ load counts error', e) }
    })
  }
]
