import { state } from 'cerebral'
import panelsOnPop from './panelsOnPop'
import config from '@/config'
import { getSessionId } from '@/utils'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'

const {
  vbApiUrl
} = config

export default [
  ({ props, get, store }) => {
    const subs = get(state`auth.subs`)
    props.type = subs[props.uid].subscriptionType
    delete subs[props.uid]
    store.set('auth.subs', subs)
    fetch(`${vbApiUrl}/vb2/subscriptions/unsubscribe`, {
      method: 'POST',
      body: new URLSearchParams({
        sid: getSessionId(),
        model: props.uid
      })
    }).catch(e => console.warn('catch error', e))
  },
  panelsOnPop,
  ({ get, store, props }) => {
    let count = get(state`auth.subsCount`) || 0
    count--
    store.set(state`auth.subsCount`, count)

    sendAmplitudeEvent('subscriptions', {
      action: 'unsubscribe',
      type: props.type.toLowerCase(),
      counterparty_id: props.uid,
      age_rating: getUserAgeRating(props.uid),
      sent_coins: 0
    })
  }
]

