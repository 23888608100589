import config from '@/config'
import { getState, setStoreWrapper } from '../../utils/StoreManager'
import { getSessionId } from '../../utils'
const { ftCoreUrl } = config
import { loadUsers } from '@/utils/userLoader'
import { getUser } from '../../utils/userLoader'
import { getProfilePostId } from '../../utils/userUtil'
import { checkMySubscription } from '../../utils/subscriptionUtil'
import { updateFansDate, updateMatchDate } from '../../utils/updateWatchedDate'

const FANS_INFO = 'fansInfo'
const FANS_LOADING_STATUS = 'fansLoadingStatus'

let errorTime

export function loadFansInfo(uids) {
  if (notLoadExtraFans || !uids){
    return
  }
  let fansLoadingStatus = getState(FANS_LOADING_STATUS) || {}
  let fansInfo = getFansInfoSource()
  let uidArray = uids.split(',')
  let needLoadUids = []
  uidArray.forEach(uid => {
    if (!fansLoadingStatus[uid] && !fansInfo[uid]){
      fansLoadingStatus[uid] = true
      needLoadUids.push(uid)
    }
  })

  //если не осталось ничего незагруженного и не грузящегося то ничего не делаем
  if (!needLoadUids.length || !getSessionId() || (errorTime && errorTime - Date.now() < 5000)){
    return
  }

  setStoreWrapper(FANS_LOADING_STATUS, fansLoadingStatus)

  try {
    fetch(`${ftCoreUrl}/api/v1/subscriptions/followers?followers=${needLoadUids.join(',')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    }).then(async fResponse => {
      let fansInfos = await fResponse.json()
      let currentFansInfo = getFansInfoSource()
      let needUpdate = false
      try {
        Object.keys(fansInfos.followers).map(uid => {
          currentFansInfo[uid] = fansInfos.followers[uid]
          needUpdate = true
        })

        if (needUpdate){
          setStoreWrapper(FANS_INFO, currentFansInfo)
        }
        console.log('@@@ load loadFanInfo test')
      } catch (e) {
        errorTime = Date.now()
        console.log('@@@ load loadFanInfo error', e)
      }
    }).catch(e => {
      console.warn('loadFanInfo catch error', e)
      errorTime = Date.now()}
    )
  } catch (e) {
    errorTime = Date.now()
    console.log('@@@ loadFanInfo error', e)
  }
}

export function getFanInfo(uid) {
  let fansInfo = getFansInfoSource()
  if (!fansInfo[uid]){
    loadFansInfo(uid)
  }

  return fansInfo[uid]
}

export function addFan(uid, fanInfo) {
  let fansInfo = getFansInfoSource()
  fansInfo[uid] = fanInfo
  setStoreWrapper(FANS_INFO, fansInfo)

  loadUsers([uid], async () => {
    if (getProfilePostId(uid)){
      setStoreWrapper('auth.newFollowersCount', (getState('auth.newFollowersCount') || 0) + 1)

      if (checkMySubscription(uid)){
        setStoreWrapper('auth.newMatchCount', (getState('auth.newMatchCount') || 0) + 1)
      }
    }
  })
}

export function dellFan(uid) {
  let fansInfo = getFansInfoSource()
  delete fansInfo[uid]
  setStoreWrapper(FANS_INFO, fansInfo)
}

function getFansInfoSource() {
  return getState(FANS_INFO) || {}
}

export function setNewFansViewed() {
  updateFansDate()
  setStoreWrapper('auth.newFollowersCount', 0)
}

export function setNewMatchViewed() {
  updateMatchDate()
  setStoreWrapper('auth.newMatchCount', 0)
}

let notLoadExtraFans

export function addFansOnAuth(fans){
  if (fans && fans.length){
    let fansInfo = getFansInfoSource()
    fans.forEach(uid => fansInfo[uid] = true)
    setStoreWrapper(FANS_INFO, fansInfo)
  }

  notLoadExtraFans = !fans || fans.length < 50
}
